<template>
  <div>
    <v-data-table
      :items="emisiones"
      :headers="headers"
      :loading="loading"
      no-data-text="Sin pólizas para mostrar"
      no-results-text="Sin resultados para mostrar"
      loading-text="Cargando..."
      :footer-props="{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Elementos por página',
      }"
    >
      <template v-slot:item.ver="{ item }">
        <v-btn small icon @click="verEmision(item.id)">
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
      </template>

      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de
          {{ itemsLength }} pólizas
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    emisiones: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: "Póliza",
          value: "poliza",
        },
        {
          text: "Cliente",
          value: "clienteNombre",
        },
        {
          text: "Agente",
          value: "nombreAgente",
        },
        {
          text: "Estatus Póliza",
          value: "status",
        },
        {
          text: "Prima Neta",
          value: "prima_neta",
        },
        {
          text: "Inicio de Vigencia",
          value: "inicio",
        },
        {
          text: "Fin de Vigencia",
          value: "fin",
        },
        {
          text: "Periodicidad",
          value: "periodicidad",
        },
        {
          text: "Ramo",
          value: "ramo",
        },
        {
          text: "Aseguradora",
          value: "aseguradoraNombre",
        },
        {
          text: "Ejecutivo",
          value: "ejecutivoNombre",
        },
        {
          text: "Ver",
          value: "ver",
        },
      ],
    };
  },

  methods: {
    verEmision(id) {
      this.$router.push(`/emision/${id}`);
    },
  },
};
</script>
